/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import "../../intl.js";
import { substitute as i } from "../../intl/substitute.js";
function t(i) {
  return i.icon ? i.icon : "image" in i && i.image || i.className ? void 0 : "question";
}
function n(i) {
  return i ? {
    backgroundImage: `url(${i})`
  } : {};
}
function e({
  action: t,
  feature: n
}) {
  const e = n?.attributes,
    o = "image" in t ? t.image : void 0;
  return o && e ? i(o, e) : o ?? "";
}
export { t as getActionIcon, n as getActionStyles, e as substituteActionImage };